<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Contact support
		</h1>
		<div class="text-left q-my-md">
			Contact our support team 24/7 to report a problem, ask a question, or to request training.
			Help us to help you - screenshots, information about the steps you've taken so far,
			date and time of any message or reports that you need assistance with etc,
			will all help us to resolve your query more quickly.
		</div>

		<div class="q-my-lg">
			<q-btn
				outline
				icon-right='send'
				label='contact'
				color='primary'
				@click="contactUsSendData()"
			/>
		</div>
		<div class="text-left q-my-md">
			If you are unable to access the contact form due to your browser or email settings,
			please contact our support team via email to
			support@alertcascade.co.uk - this address is monitored 24/7/365.
		</div>
		<div class="text-left q-my-md">
			For critical issues that are preventing you from sending messages during a live incident,
			we will be in touch within the next 30 minutes.
			For all other issues, please refer to your Service Level Agreement for relevant timescales.
		</div>
	</div>
</template>

<script>
export default {
	name: "ContactSupport",
	meta: {
		title: 'Alert Cascade - Contact Support'
	},
	data() {
		return {
			contact_us_email: '',
			is_active: false,
		};
	},
	methods: {
		async contactUsSendData() {
			window.open('mailto:support@alertcascade.co.uk?subject=Contact Support');
		},
	},
}
</script>

<style lang="scss" scoped>
.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}
p{
	text-align: left;
}

</style>
